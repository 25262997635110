import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {canNavigate} from "@/libs/acl/routeProtection";
import app from './routes/app'
import reports from './routes/reports'
import fotostudio from './routes/fotostudio'
import i18n from '@/libs/i18n'
import getBrand from "@/config/branding-config";
import ability from "@/libs/acl/ability";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...app,
        ...reports,
        ...fotostudio,
        {
            path: '*',
            redirect: 'pages-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
    const brand = getBrand();
    document.title = `${brand.appName} | ${i18n.t(to.meta.pageTitle)}`
})

router.beforeEach((to, _, next) => {
    if (to.meta.reports) store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    else store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    const isLoggedInBefore = store.getters['auth/isLoggedInBefore'];
    const isTwoFactorAuthRequired = store.getters['auth/isTwoFactorAuthRequired']
    const isAuth = isLoggedIn && !isTwoFactorAuthRequired
    if (isLoggedIn) {
        if (isTwoFactorAuthRequired && to.name !== "two-factor-auth") return next({name: 'two-factor-auth'})
        if (!isTwoFactorAuthRequired && to.name === "two-factor-auth") return next({name: 'home'})
    } else if (to.name === "two-factor-auth") {
        return next({name: 'login'})
    }
    if (isAuth && !isLoggedInBefore && to.name !== "change-password") {
        return next({name: 'change-password'})
    }
    if (isLoggedIn && to.meta.redirectIfLoggedIn) {
        return next({name: 'home'})
    }
    if (to.name === "home" && ability.can("read" || 'manage', "photo-studio-photographer")) {
        return next({name: 'fotostudio-search'})
    }
    if (!to.meta.redirectIfLoggedIn) {
        if (!isLoggedIn) {
            return next({name: 'login'})
        }
        if (to.name !== "pages-404"
            && to.name !== "not-authorized"
            && to.name !== "two-factor-auth"
            && to.name !== "change-password"
            && !canNavigate(to)) {
            return next({name: 'not-authorized'})
        }
    }
    return next()
})

export default router
