import {$themeConfig} from '@themeConfig';
import store from '@/store/index'

export default function getBrand() {
    const brands = $themeConfig.layout.branding.brands
    let brandingCurrent = store.state.appConfig.layout.branding
    if (!brandingCurrent) {
        const host = window.location.host
        brandingCurrent = brands.find(obj => host.includes(obj.app))
        if (brandingCurrent) store.dispatch('appConfig/updateBranding', brandingCurrent)
        else {
            brandingCurrent = brands.find(obj => obj.app === $themeConfig.layout.branding.default)
            store.dispatch('appConfig/updateBrandingElement', brandingCurrent)
        }
    }
    return brandingCurrent
}
