import Vue from "vue";
import {localize} from "vee-validate";
import i18n from "@/libs/i18n"

export default {
    namespaced: true,
    state: {
        lang: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en',
    },
    getters: {
        lang: state => {
            return state.lang
        },
        locale: state => {
            if (state.lang === 'en') {
                return "en-US"
            }
            return "de-DE"
        },
    },
    mutations: {
        SET_LANG(state, lang) {
            state.lang = lang
        },
    },
    actions: {
        switch({commit}, { lang }) {
            localStorage.setItem('lang',lang)
            commit('SET_LANG', lang)
            i18n.locale = lang
            i18n.fallbackLocale = lang
            Vue.prototype.$http.defaults.headers.Language = lang
            localize(lang)
        },
    },
}
