<template>
  <div class="parent">
    <vue-element-loading
:active="show"
:color="getBranding('primaryColor')"
/>
    <slot />
  </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    VueElementLoading
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  }
}
</script>
