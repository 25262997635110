export default [
    {
        path: '/fotostudio/articles',
        name: 'fotostudio-articles',
        component: () => import('@/views/fotostudio/pages/articles.vue'),
        meta: {
            pageTitle: "fotostudio.nav.articles",
            resource: 'photo-studio-s4s-staff',
        },
    },
    {
        path: '/fotostudio/articles/:sku',
        name: 'fotostudio-article',
        component: () => import('@/views/fotostudio/pages/article.vue'),
        meta: {
            pageTitle: "fotostudio.nav.article",
            breadcrumb: [
                {
                    text: 'fotostudio.nav.articles',
                    to: {name: 'fotostudio-articles'},
                    active: false,
                },
                {
                    text: 'fotostudio.nav.article',
                    active: true,
                },
            ],
            resource: 'photo-studio-s4s-staff',
        },
    },
    {
        path: '/fotostudio/search/:ean',
        name: 'fotostudio-search-ean',
        component: () => import('@/views/fotostudio/pages/photographer/article.vue'),
        meta: {
            pageTitle: "fotostudio.nav.article",
            breadcrumb: [
                {
                    text: 'fotostudio.nav.search',
                    to: {name: 'fotostudio-search'},
                    active: false,
                },
                {
                    text: 'fotostudio.nav.article',
                    active: true,
                },
            ],
            resource: 'photo-studio-photographer',
        },
    },
    {
        path: '/fotostudio/search',
        name: 'fotostudio-search',
        component: () => import('@/views/fotostudio/pages/photographer/search.vue'),
        meta: {
            pageTitle: "fotostudio.nav.search",
            resource: 'photo-studio-photographer',
        },
    },
    {
        path: '/fotostudio/update',
        name: 'fotostudio-update-options',
        component: () => import('@/views/fotostudio/pages/options/index.vue'),
        meta: {
            pageTitle: "fotostudio.nav.update",
            resource: 'photo-studio-s4s-staff',
        },
    },
    {
        path: '/fotostudio/brand-description',
        name: 'fotostudio-brand-description',
        component: () => import('@/views/fotostudio/pages/brand-description.vue'),
        meta: {
            pageTitle: "fotostudio.nav.brand-description",
            resource: 'photo-studio-s4s-staff',
        },
    },
]
