import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
    BButton,
    BCardText,
    BCardTitle,
    BCardBody,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BRow,
    BCard,
    BTable,
    BPagination,
    BSidebar,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BBadge,
    BAvatar,
    BButtonGroup,
    BSpinner,
    BFormFile,
    BTd,
    BTr,
    BTh,
    BThead,
    BTbody,
    BFormText,
    BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {BFormSelect} from "bootstrap-vue/src/components/form-select";
import Loading from "./components/Loading.vue";
import store from "@/store";

localize(store.getters['lang/lang'])
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-button', BButton)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-text', BFormText)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-body', BCardBody)
Vue.component('b-img', BImg)
Vue.component('b-link', BLink)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('vuexy-logo', VuexyLogo)
Vue.component('b-card', BCard)
Vue.component('v-select', vSelect)
Vue.component('b-table', BTable)
Vue.component('b-pagination', BPagination)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-card-header', BCardHeader)
Vue.component("loading", Loading)
Vue.component("b-badge", BBadge)
Vue.component("b-avatar", BAvatar)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-spinner', BSpinner)
Vue.component('b-form-file', BFormFile)
Vue.component('b-td', BTd)
Vue.component('b-tr', BTr)
Vue.component('b-tbody', BTbody)
Vue.component('b-thead', BThead)
Vue.component('b-th', BTh)
