const reports = [
    {
        path: '/reports/trading-overview/:sku?',
        name: 'trading-overview',
        component: () => import('@/views/reports/trading-overview.vue'),
        meta: {
            resource: 'trading-overview',
        }
    },
    {
        path: '/reports/platform-report',
        name: 'platform-report',
        component: () => import('@/views/reports/platform-report.vue'),
        meta: {
            resource: 'platform-report',
        }
    },
    {
        path: '/reports/country-report',
        name: 'country-report',
        component: () => import('@/views/reports/country-report.vue'),
        meta: {
            resource: 'country-report',
        }
    },
    {
        path: '/reports/marketing-planer',
        name: 'marketing-planer',
        component: () => import('@/views/reports/marketing-planer/index.vue'),
        meta: {
            resource: 'marketing-planer',
        }
    },
    {
        path: '/reports/brand-overview',
        name: 'brand-overview',
        component: () => import('@/views/reports/brand-overview.vue'),
        meta: {
            resource: 'brand-overview',
        }
    },
    {
        path: '/reports/brand-by-platform',
        name: 'brand-by-platform',
        component: () => import('@/views/reports/brand-by-platform.vue'),
        meta: {
            resource: 'brand-by-platform',
        }
    },
    {
        path: '/reports/live-share-report',
        name: 'live-share-error-report',
        component: () => import('@/views/reports/live-share-error-report.vue'),
        meta: {
            resource: 'live-share',
        }
    },
]

reports.forEach((value,index) => {
    reports[index].meta = {...reports[index].meta,
        ...{
            reports: true,
            hideDefaultBreadcrumb: true,
            breadcrumbTitle: "Reports",
            pageTitle: `reports.names.${reports[index].name}`,
        }}
})

export default reports
