export default [
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/pages/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Not Authorized",
        },
    },
    {
        path: '/pages-404',
        name: 'pages-404',
        component: () => import('@/views/pages/Error404.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Page 404",
        },
    },
    {
        path: '/two-factor-auth',
        name: 'two-factor-auth',
        component: () => import('@/views/auth/TwoFactorAuth.vue'),
        meta: {
            layout: 'full',
            pageTitle: "two_factor_auth",
        },
    },
]
