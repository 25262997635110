import Vue from 'vue'
import axios from 'axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import i18n from '@/libs/i18n'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_API_URL ,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Language: localStorage.getItem("lang"),
    }
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(
    response => response,
    error => {
        const { config, response } = error
        if(!response) {
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Error',
                    icon: 'TrashIcon',
                    variant: 'danger',
                    text: error.message,
                },
            })
            return Promise.reject(error.message)
        }

        if (response.status === 401) {
            store.dispatch('auth/logout')
            window.location.href = "/login";
            return Promise.reject(error.message)
        }

        if (response.status === 404) {
            window.location.href = "/pages-404";
            return Promise.reject(error.message)
        }

        if (response.status === 403) {
            window.location.href = "/not-authorized";
            return Promise.reject(error.message)
        }

        if (!response.data.errors) {
            let message = ""
            if (response.data.exception) message = i18n.t('Something went wrong!')
            else {
                message = response.data.message
                if (Array.isArray(response.data.message)) {
                    message = message[0]
                }
            }
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Error',
                    icon: 'TrashIcon',
                    variant: 'danger',
                    text: message,
                },
            })
            return Promise.reject(message)
        }
        return Promise.reject(response)
    }
)

export default axiosIns
