<template>
  <div>
    <img
        v-for="brand in brands"
        v-show="currentBrand === brand.app"
        :key="brand.app"
        :src="brand.icon"
        :alt="brand.app"
    >
  </div>
</template>

<script>
import {$themeConfig} from '@themeConfig';

export default {
  data() {
    return {
      currentBrand: this.getBranding('app'),
      brands: $themeConfig.layout.branding.brands
    }
  },
}
</script>
