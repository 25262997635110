import Vue from 'vue'
import _ from "lodash";
import store from '@/store/index'
import ability from "@/libs/acl/ability";
import getBrand from "@/config/branding-config";

Vue.mixin({
    methods: {
        isUserLoggedIn() {
            return store.getters['auth/isLoggedIn'];
        },
        auth() {
            return store.getters['auth/user'];
        },
        getSalesPermissionName() {
            const salesPermission = store.getters['auth/user'].sales_permission
            if (salesPermission === 0) return "Brand WS"
            if (salesPermission === 1) return "Brand AG"
            return "Brand WS+AG"
        },
        can(resource, action) {
            return ability.can(action || 'manage', resource)
        },
        getAppName() {
            return this.getBranding('appName')
        },
        lang() {
            return store.getters['lang/lang'];
        },
        locale() {
            return store.getters['lang/locale'];
        },
        numberFormat(value, options = {}) {
            if(Number.isNaN(Number(value))){
                return value
            }
            return new Intl.NumberFormat(
                this.locale(),
                {...{
                        minimumFractionDigits: options.maximumFractionDigits < 2 ? 0 : 2 ,
                        maximumFractionDigits: 2
                    },
                    ...options}
            ).format(value)
        },
        priceFormat(value, options = {}) {
            return this.numberFormat(value, {...{style: 'currency', currency: 'EUR'}, ...options})
        },
        percentageFormat(value, options = {}) {
            if(Number.isNaN(Number(value))){
                return value
            }
            return this.numberFormat(value / 100, {...{style: 'percent'}, ...options})
        },
        booleanFormat(value) {
           if(value === 'no') return this.$i18n.t('no')
           return this.$i18n.t('yes')
        },
        formatterHelper(value, format, options = {}) {
            const type = _.isObject(format) ? format.type : format;
            const jsOption = {...this.convertBackendOptions(format), ...options};
            switch (type) {
                case 'price':
                    return this.priceFormat(value, jsOption);
                case 'percentage':
                    return this.percentageFormat(value, jsOption);
                case 'number':
                    return this.numberFormat(value, jsOption);
                case 'boolean':
                    return this.booleanFormat(value);
                default:
                    return value;
            }
        },
        convertBackendOptions(options = {}) {
            return _.pickBy({
                maximumFractionDigits: _.get(options, 'decimals'),
            }, _.isLength);
        },
        getBranding(config) {
            return getBrand()[config]
        },
        listYears() {
            const year = new Date().getFullYear()
            return Array.from({length: year - (year - 20)}, (value, index) => (year - 10) + index).reverse()
        }
    }
})
