export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Login",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Forgot Password",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'auth-reset-password',
        props: 'token',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
            pageTitle: "reset_password",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('@/views/auth/ChangePassword.vue'),
        meta: {
            layout: 'full',
            pageTitle: "change_password",
        },
    },
]
