export default [
    {
        path: '/settings/platform-mapping',
        name: 'platform-mapping',
        component: () => import('@/views/settings/PlatformMapping.vue'),
        meta: {
            pageTitle: "settings.platform-mapping.name",
            resource: 'roles',
            action: 'read',
        },
    },
    {
        path: '/settings/platform-groups',
        name: 'platform-groups',
        component: () => import('@/views/settings/PlatformGroups.vue'),
        meta: {
            pageTitle: "settings.platform-groups.name",
            resource: 'roles',
            action: 'read',
        },
    },
]
