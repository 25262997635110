import auth from './auth'
import home from './home'
import roles from './roles'
import users from './users'
import profile from './profile'
import settings from './settings'
import other from './other'

export default [
    ...auth,
    ...home,
    ...roles,
    ...users,
    ...profile,
    ...settings,
    ...other,
]
