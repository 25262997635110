import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import lodash from 'lodash';
import VueLodash from 'vue-lodash'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

// Global Components
import './global-components'

// Global Mixin
import './mixins/generalMixin'

// 3rd party plugins
import '@/libs/axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'
import '@/libs/acl'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// lodash
Vue.use(VueLodash, { lodash })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
