export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/index.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      resource: 'users',
      action: 'read',
    },
  },
]
