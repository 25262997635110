import Vue from "vue";
import {$themeConfig} from '@themeConfig';

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
        is_two_factor_auth_required: localStorage.getItem('is_two_factor_auth_required') === "true",
        account_id: localStorage.getItem('account_id') || '',
    },
    getters: {
        isLoggedIn: state => {
            return !!state.token
        },
        user: state => {
            return state.user
        },
        isLoggedInBefore: state => {
            return state.user.isLogged ?? 0
        },
        isTwoFactorAuthRequired: state => {
            return state.is_two_factor_auth_required ?? false
        },
        getAccountId: state => {
            return state.account_id
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_IS_TWO_FACTOR_AUTH_REQUIRED(state, value) {
            state.is_two_factor_auth_required = value
        },
        SET_ACCOUNT_ID(state, value) {
            state.account_id = value
        },
    },
    actions: {
        login({commit, dispatch}, {token, user, isTwoFactorAuthRequired = false, accountId}) {
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('is_two_factor_auth_required', isTwoFactorAuthRequired)
            if (accountId) localStorage.setItem('account_id', accountId)
            commit('appConfig/UPDATE_BRANDING', user.brand, {root: true})
            Vue.prototype.$http.defaults.headers.Authorization = `Bearer ${token}`
            commit('SET_TOKEN', token)
            commit('SET_USER', user)
            commit('SET_IS_TWO_FACTOR_AUTH_REQUIRED', isTwoFactorAuthRequired)
            if (accountId) commit('SET_ACCOUNT_ID', accountId)
            dispatch('lang/switch', {lang: user.lang}, {root: true})
            const brandName = user.roles[0].brand;
            const brands = $themeConfig.layout.branding.brands
            const branding = brands.find(obj => obj.app === brandName)
            dispatch('appConfig/updateBranding', branding, {root: true})
        },
        logout({commit}) {
            commit('SET_TOKEN', '')
            commit('SET_USER', '')
            commit('SET_ACCOUNT_ID', '')
            commit('appConfig/UPDATE_BRANDING', null, {root: true})
            commit('SET_IS_TWO_FACTOR_AUTH_REQUIRED', false)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('branding')
            localStorage.removeItem('is_two_factor_auth_required')
            localStorage.removeItem('account_id')
            delete Vue.prototype.$http.defaults.headers.Authorization
        },
        updateProfile({commit, dispatch, getters}, {user}) {
            localStorage.setItem('user', JSON.stringify(user))
            commit('SET_USER', user)
            dispatch('lang/switch', {lang: user.lang}, {root: true})
        },
        updatePassword({commit, dispatch, getters}, {user}) {
            localStorage.setItem('user', JSON.stringify(user))
            commit('SET_USER', user)
        },
    },
}
