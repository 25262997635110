export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/index.vue'),
    meta: {
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'read',
    },
  },
  {
    path: '/roles/add',
    name: 'roles-add',
    component: () => import('@/views/roles/add.vue'),
    meta: {
      pageTitle: 'Add Role',
      breadcrumb: [
        {
          text: 'Roles',
          to: {name: 'roles'},
          active: false,
        }, {
          text: 'Add Role',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'create',
    },
  },
  {
    path: '/roles/:id',
    name: 'roles-edit',
    component: () => import('@/views/roles/edit.vue'),
    meta: {
      pageTitle: 'Edit Role',
      breadcrumb: [
        {
          text: 'Roles',
          to: {name: 'roles'},
          active: false,
        }, {
          text: 'Edit Role',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'update',
    },
  },
]
